import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { _getPlatform, _isMobile } from "./helpers";
import "../css/sale.css";
import useActiveSale from "hooks/useActiveSale";
import PromocodeButton from "./PromocodeButton";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SaleModal = () => {
    const { handleSetActiveSale, handleCloseSaleModal } = useActiveSale();

    const sales = useSelector(({ pages }) => pages.sales);
    const closeModalByCross = useSelector(
        (state) => state.config.data.CONFIG_close_modal_by_cross
    );
    const activeSale = useSelector((state) => state.saleModal.activeSale);

    let dialogSaleProps = { open: !!activeSale, maxWidth: "md" };
    if (_isMobile()) {
        dialogSaleProps.TransitionComponent = Transition;
        dialogSaleProps.fullScreen = true;
    }

    const urlChangeEventListener = () => {
        let url = new URL(window.location.href);
        if (!url.searchParams.has("sale_id")) {
            handleCloseSaleModal();
        }
    };

    useEffect(() => {
        if (activeSale) {
            window.addEventListener("popstate", urlChangeEventListener);
        }
        return () => {
            window.removeEventListener("popstate", urlChangeEventListener);
        };
    }, [activeSale]);

    useEffect(() => {
        const urlParams = new URL(window.location.href).searchParams;
        const paramsSaleID = urlParams.get("sale_id");
        if (paramsSaleID) {
            const sale = sales.find((sale) => sale.saleID == paramsSaleID);
            if (sale) {
                handleSetActiveSale(sale);
            } else if (!sale && activeSale) {
                handleCloseSaleModal();
            }
        }
    }, [sales]);

    if (!activeSale) {
        return null;
    }

    return (
        <Dialog
            {...dialogSaleProps}
            className="sale-dialog"
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: _isMobile() ? "0px" : "15px",
                },
            }}
            onClose={(event, reason) => {
                if (reason === "escapeKeyDown") {
                    handleCloseSaleModal();
                }
                if (
                    reason === "backdropClick" &&
                    closeModalByCross !== "active"
                ) {
                    handleCloseSaleModal();
                }
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseSaleModal}
                aria-label="close"
                className="modal-close"
                sx={
                    _getPlatform !== "vk" && _isMobile()
                        ? {
                              right: "17px",
                              left: "unset",
                          }
                        : {}
                }
            >
                <CloseIcon />
            </IconButton>
            <div className="sale-modal--container">
                <div className="sale-modal">
                    <h2 className="sale-modal--title">
                        {activeSale.saleTitle}
                    </h2>

                    <div
                        className="sale--content"
                        dangerouslySetInnerHTML={{
                            __html: activeSale.saleContent,
                        }}
                    ></div>
                    {activeSale.promocodeCode ? (
                        <PromocodeButton code={activeSale.promocodeCode} />
                    ) : null}
                </div>
            </div>
        </Dialog>
    );
};

export default SaleModal;
