import React from "react";

import { completelyRemovePromocode } from "redux/actions/cart";
import { useDispatch, useSelector } from "react-redux";

import { LoadingButton } from "@mui/lab";

import usePromocode from "hooks/usePromocode";

const PromocodeButton = ({ code, onApply = () => {}, onError = () => {} }) => {
    const dispatch = useDispatch();
    const { handleApplyPromocode, loading } = usePromocode({
        onApply,
        onError,
    });

    const promocode = useSelector((state) => state.cart.promocode);
    const conditionalPromocode = useSelector(
        (state) => state.cart.conditionalPromocode
    );

    return (
        <LoadingButton
            onClick={() => {
                dispatch(completelyRemovePromocode());
                handleApplyPromocode(code, true);
            }}
            loading={loading}
            variant="contained"
            size="small"
            disabled={
                promocode?.code === code || conditionalPromocode?.code === code
            }
            tooltip
            sx={{
                "&.MuiLoadingButton-root.Mui-disabled": {
                    bgcolor: "var(--counter-buttons-color)",
                    color: "var(--text-color)",
                },
            }}
        >
            {promocode?.code === code || conditionalPromocode?.code === code
                ? "Промокод в корзине"
                : "Применить промокод"}
        </LoadingButton>
    );
};

export default PromocodeButton;
